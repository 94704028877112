import React from "react";
import { Card } from "reactstrap";
import { SettingsTabPages } from "../Constants/SettingsConstants";
import { General } from "../TabPages/General";
import { Roster } from "../TabPages/Roster";
import { Xero } from "../TabPages/Xero";
import { Timesheets } from "../TabPages/Timesheets";
import { Profile } from "../TabPages/Profile";
import { Leave } from "../TabPages/Leave";
import { Roles } from "../TabPages/Roles";
import { Permissions } from "../TabPages/Permissions";
import { SystemUsers } from "../TabPages/SystemUsers";
import { Workorder } from "../TabPages/Workorder";
import { WorkOrderTemplates } from "../TabPages/WorkorderTemplates";
import { Notifications } from "../TabPages/Notifications";
import Sites from "../../Sites/Index";
export default function SettingsTabContent({ activeTab }) {
  const TabOutlet = () => {
    switch (activeTab) {
      case SettingsTabPages.General:
        return <General />;
      case SettingsTabPages.SystemUsers:
        return <SystemUsers />;
      case SettingsTabPages.Permissions:
        return <Permissions />;
      case SettingsTabPages.Roster:
        return <Roster />;
      case SettingsTabPages.Timesheets:
        return <Timesheets />;
      case SettingsTabPages.Leave:
        return <Leave />;
      case SettingsTabPages.Roles:
        return <Roles />;
      case SettingsTabPages.Sites:
        return <Sites />;
      case SettingsTabPages.Workorder:
        return <Workorder />;
      case SettingsTabPages.WorkorderTemplates:
        return <WorkOrderTemplates />;
      case SettingsTabPages.Notifications:
        return <Notifications />;
        case SettingsTabPages.Xero:
          return <Xero />;
      case SettingsTabPages.Profile:
        return <Profile />;
      
      default:
        return <Profile />;
    }
  };

  return (
    //Fragment just in case
    <>{TabOutlet()}</>
  );
}
