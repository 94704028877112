// import React from 'react'
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import { SettingsTabPages } from "../Constants/SettingsConstants";
import TabControlNavItem from "../../../Components/Common/TabControlNavItem";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function SettingsTabControl({
  activeTabSetter,
  activeTab,
  showGeneralSettings,
  showSystemUsers,
  showSites,
  showRolesAndPermissions,
}) {
  const history = useNavigate();

  function clickHandler(navObject) {
    console.log(navObject, "navObject12");
    if (navObject.displayText == "General") {
      history("/business_settings/general");
    } else if (navObject.displayText == "System Users") {
      history("/business_settings/system_users");
    } else if (navObject.displayText == "Permissions") {
      history("/business_settings/permissions");
    } else if (navObject.name == "sites") {
      history("/business_settings/sites");
    } else if (navObject.name == "work_order") {
      history("/business_settings/workorder");
    } else if (navObject.name == "work_order_templates") {
      history("/business_settings/workorder_templates");
    } else if (navObject.displayText == "Roster") {
      history("/business_settings/roster");
    } else if (navObject.displayText == "Timesheets") {
      history("/business_settings/timesheets");
    } else if (navObject.displayText == "Leave") {
      history("/business_settings/leave");
    } else if (navObject.displayText == "Roles") {
      history("/business_settings/roles");
    }else if (navObject.displayText == "Notifications") {
      history("/business_settings/notifications");
    }else if (navObject.displayText == "Xero") {
      history("/business_settings/Xero");
    }else if (navObject.displayText == "Profile") {
      history("/business_settings/profile");
    }
  
    activeTabSetter(navObject);
  }

  // function clickHandler(navObject) {
  //   if (
  //     (navObject.name === "system_users" && !showSystemUsers) ||
  //     ((navObject.name === "roles" || navObject.name === "permissions") && !showRolesAndPermissions)
  //   ) {
  //     // If the user doesn't have permissions, prevent navigation
  //     return;
  //   }

  //   // If the user has permissions, navigate to the specified URL
  //   switch (navObject.displayText) {
  //     case "General":
  //       history("/business_settings/general");
  //       break;
  //     case "System Users":
  //       history("/business_settings/system_users");
  //       break;
  //     case "Permissions":
  //       history("/business_settings/permissions");
  //       break;
  //     case "Roster":
  //       history("/business_settings/roster");
  //       break;
  //     case "Timesheets":
  //       history("/business_settings/timesheets");
  //       break;
  //     case "Leave":
  //       history("/business_settings/leave");
  //       break;
  //     case "Roles":
  //       history("/business_settings/roles");
  //       break;
  //     case "Profile":
  //       history("/business_settings/profile");
  //       break;
  //     default:
  //       break;
  //   }

  //   activeTabSetter(navObject);
  // }

  return (
    <Card>
      <CardBody>
        <Nav pills vertical>
          {Object.values(SettingsTabPages).map(
            (tabObject) =>
              // Check permission to show the tab based on its name
              (tabObject.name !== "system_users" || showSystemUsers) &&
              (tabObject.name !== "general" || showGeneralSettings) &&
              (tabObject.name !== "sites" || showSites) &&
              !(
                (tabObject.name === "roles" ||
                  tabObject.name === "permissions") &&
                !showRolesAndPermissions
              ) && (
                <TabControlNavItem
                  navObject={tabObject}
                  onClickCallback={clickHandler}
                  key={tabObject.id}
                  activeTab={activeTab}
                />
              )
          )}
        </Nav>
      </CardBody>
    </Card>
  );
}
